import React from 'react'
import { Box, Container, Paper, Grid, Typography, Card, CardActionArea, CardContent } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import Bank1 from '../../assets/images/bank11.png'
import Bank2 from '../../assets/images/bank22.png'
import Bank3 from '../../assets/images/bank33.png'
import Bank4 from '../../assets/images/bank44.png'
import Bank5 from '../../assets/images/bank55.png'

// 样式
const styles = makeStyles(theme => ({
  root: {
  },
  title: {
    color: '#EC4B43',
    fontSize: 14,
    paddingTop: 10,
    paddingBottom: 10,
    paddingLeft: 20,
    paddingRight: 20,
  },
  image_box: {
    width: '20%',
    textAlign: 'center',
    display: 'inline-block',
    verticalAlign: 'middle',
  },
  image: {
    height: 69,
  }
}))

// 银行列表
const banks = [Bank2, Bank3, Bank1, Bank4, Bank5];

// 合作伙伴
const Partner = props => {
  const classes = styles();

  return <Box
    component={Container}
    className={classes.root}
  >
    <Grid
      container
    >
      <Grid
        item
        xs={12}
      >
        <Paper
          square={true}
          elevation={0}
          style={{
            borderBottom: '1px solid #ddd',
          }}
        >
          <Typography
            className={classes.title}
            variant='caption'
            align='left'
            component='div'
            padding={5}
          >
            合作伙伴
          </Typography>
        </Paper>
      </Grid>
      <Grid
        item
        xs={12}
      >
        <Paper
          square={true}
          elevation={0}
        >
          {banks.map((item, index) =>
            <Card
              className={classes.image_box}
              key={index}
              square={true}
              elevation={0}
            >
              <CardActionArea
              >
                <CardContent
                  className={classes.image}
                >
                  <img
                    src={item}
                    alt=''
                  />
                </CardContent>
              </CardActionArea>
            </Card>)}
        </Paper>
      </Grid>
    </Grid>
  </Box>
}

export default Partner;